import PropTypes from "prop-types"
import { get as getCookie } from "js-cookie"
import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import LUNavigationBar from "../../components/LUNavigationBar"
import { Res } from "../../utils/constants"
import CtaLinks from "../../utils/CtaLinks"
import { COOKIE } from "../../helpers/constants"

import { useScreenSize } from "../../utils/hooks"

import leoVegasLogo from "../../assets/leovegas-casino-logo.png"
import brandTheme from "../theme"
import {
  getImageFluid,
  getLanguage,
  getLogos, // setNetreferCookies,
  getRawMarkdown
} from "../../helpers"
import MOBanner from "../../components/MOBanner"
import LUAwards from "../../components/LUAwards"
import LUPaymentProviderBar from "../../components/LUPaymentProviderBar"
import LUCookieConsent from "../../components/LUCookieConsent"
import MOTerms from "../../components/MOTerms"
import Footer from "../../components/Footer"
import FooterLinks from "../../components/FooterLinks"

const MultiOfferTemplate = ({ data: { contentfulMultiOffer: data, contentfulGenericContent: genericData }, location }) => {
  const [showCookieConsent, setShowCookieConsent] = useState(false)
  const [newLoginUrl, setNewLoginUrl] = useState(null)
  const [ctaUrls, setCtaUrls] = useState({})
  const [newMiscLinks, setNewMiscLinks] = useState("")
  // const [hasPageLoaded, setHasPageLoaded] = useState(false)

  // useEffect(() => {
  // setHasPageLoaded(true)
  // }, [])

  useEffect(() => {
    setShowCookieConsent(!getCookie(COOKIE.isCookiePolicyAccepted))

    const urlLanguage = getLanguage(data.slug)

    const contentLanguage = genericData.language

    const ctaLinks = CtaLinks(
      data.bonusProgramId,
      data.defaultMarketingSourceId,
      data.defaultMediaId,
      genericData.iosAppId,
      genericData.downloadAppAndroidLinkType?.downloadAppAndroidLinkType,
      data.pageType,
      genericData.signupUrl,
      genericData.loginUrl,
      urlLanguage,
      contentLanguage,
      null,
      genericData.gameRulesLink
    )

    const casinoCtaLink = CtaLinks(
      data.bonusProgramId,
      data.defaultMarketingSourceId,
      data.defaultMediaId,
      genericData.iosAppId,
      genericData.downloadAppAndroidLinkType?.downloadAppAndroidLinkType,
      "casino",
      genericData.signupUrl,
      genericData.loginUrl,
      urlLanguage,
      contentLanguage,
      null,
      genericData.gameRulesLink
    )
    const liveCasinoCtaLink = CtaLinks(
      data.bonusProgramId,
      data.defaultMarketingSourceId,
      data.defaultMediaId,
      genericData.iosAppId,
      genericData.downloadAppAndroidLinkType?.downloadAppAndroidLinkType,
      "live casino",
      genericData.signupUrl,
      genericData.loginUrl,
      urlLanguage,
      contentLanguage,
      null,
      genericData.gameRulesLink
    )
    const sportsCtaLink = CtaLinks(
      data.bonusProgramId,
      data.defaultMarketingSourceId,
      data.defaultMediaId,
      genericData.iosAppId,
      genericData.downloadAppAndroidLinkType?.downloadAppAndroidLinkType,
      "sportsbook",
      genericData.signupUrl,
      genericData.loginUrl,
      urlLanguage,
      contentLanguage,
      null,
      genericData.gameRulesLink
    )

    /* setNetreferCookies({
      params: ctaLinks.urlParams,
      language: genericData.language
    }) */

    const { miscLinks, loginUrl } = ctaLinks

    const { redirectUrl: casinoSignUpUrl } = casinoCtaLink
    const { redirectUrl: liveCasinoSignUpUrl } = liveCasinoCtaLink
    const { redirectUrl: sportsSignUpUrl } = sportsCtaLink

    setNewLoginUrl(loginUrl)

    setNewMiscLinks(miscLinks)

    setCtaUrls({
      signupUrlCasino: casinoSignUpUrl,
      signupUrlLivecasino: liveCasinoSignUpUrl,
      signupUrlSports: sportsSignUpUrl
    })
  }, [])

  const {
    seoTitle,
    language,
    offersListTitle,
    casinoOffer,
    casinoDesktopImage,
    casinoMobileImage,
    casinoCtaText,
    livecasinoOffer,
    livecasinoDesktopImage,
    livecasinoMobileImage,
    livecasinoCtaText,
    sportsbookOffer,
    sportsbookDesktopImage,
    sportsbookMobileImage,
    sportsbookCtaText,
    termsOfferText,
    loginText,
    loginLinkText,
    termsTitle
  } = data
  const {
    logo,
    paymentsLogos,
    sponsorsText,
    sponsorsLogos,
    licensesLogos,
    footerDisclaimer,
    singleRgLogo,
    responsibleGamingFooterLogos,
    affiliatesLink,
    aboutLeoVegasLink,
    termsLink,
    responsibleGamingLink,
    cookiePolicyLink,
    privacyPolicyLink,
    cookieConsentContent,
    cookiePolicy,
    awardsSectionTitleLeoUniverse,
    awardsImagesLeoUniverse
  } = genericData

  const footerLinks = [
    { title: affiliatesLink, url: "https://www.leovegasaffiliates.com/" },
    { title: aboutLeoVegasLink, url: newMiscLinks?.aboutLeoVegas },
    { title: responsibleGamingLink, url: newMiscLinks?.responsibleGaming },
    { title: newMiscLinks?.gameRules?.title, url: newMiscLinks?.gameRules?.url },
    { title: termsLink, url: newMiscLinks?.terms },
    { title: cookiePolicyLink, url: newMiscLinks?.cookiePolicy },
    { title: privacyPolicyLink, url: newMiscLinks?.privacyPolicy }
  ]

  const { width, height } = useScreenSize()
  const screenSizeReady = width.value !== 0
  const isMobile = !!width.lessThan(Res._860) && !!width.lessThan(height.value)
  const boxLayout = {
    margin: {
      raw: 16,
      value: "16px"
    }
  }
  const theme = {
    ...brandTheme,
    isMobile,
    boxLayout,
    language
  }
  const backgroundColour = "#ededed"

  const casinoImage = getImageFluid(isMobile ? casinoMobileImage : casinoDesktopImage)
  const livecasinoImage = getImageFluid(isMobile ? livecasinoMobileImage : livecasinoDesktopImage)
  const sportsbookImage = getImageFluid(isMobile ? sportsbookMobileImage : sportsbookDesktopImage)

  if (!screenSizeReady) return null

  return (
    <Layout location={location} title={seoTitle} language={language} backgroundColour={backgroundColour} theme={theme} isOrangeBgLayout>
      <LUNavigationBar
        scrollBgColour={`linear-gradient(90deg, ${brandTheme.colours.tertiary}, ${brandTheme.colours.primary})`}
        logoUrl={logo?.file?.url || leoVegasLogo}
        isOntario
      />
      <main>
        <MOBanner
          offersListTitle={offersListTitle}
          casinoOffer={casinoOffer}
          casinoImage={casinoImage}
          casinoCtaText={casinoCtaText}
          livecasinoOffer={livecasinoOffer}
          livecasinoImage={livecasinoImage}
          livecasinoCtaText={livecasinoCtaText}
          sportsbookOffer={sportsbookOffer}
          sportsbookImage={sportsbookImage}
          sportsbookCtaText={sportsbookCtaText}
          termsOfferText={termsOfferText}
          loginUrl={newLoginUrl}
          loginText={loginText}
          loginLinkText={loginLinkText}
          signUpUrlsByVertical={ctaUrls}
        />
        {paymentsLogos && <LUPaymentProviderBar images={paymentsLogos} isStepsSectionVisible={false} />}
        <LUAwards title={awardsSectionTitleLeoUniverse} awardImages={awardsImagesLeoUniverse} />
        <MOTerms casinoOffer={casinoOffer} livecasinoOffer={livecasinoOffer} sportsbookOffer={sportsbookOffer} termsTitle={termsTitle} />
        {showCookieConsent && (
          <LUCookieConsent
            disclaimerTitle={cookieConsentContent.disclaimerTitle}
            disclaimer={cookieConsentContent.disclaimer}
            cookiePolicyLink={cookieConsentContent.cookiePolicyLink}
            acceptButtonText={cookieConsentContent.buttons.accept}
            allowAllButtonText={cookieConsentContent.buttons.allowAll}
            confirmButtonText={cookieConsentContent.buttons.confirm}
            manageButtonText={cookieConsentContent.buttons.manage}
            necessary={cookieConsentContent.necessary}
            functional={cookieConsentContent.functional}
            analytics={cookieConsentContent.analytics}
            marketing={cookieConsentContent.marketing}
            cookiePolicy={getRawMarkdown(cookiePolicy)}
          />
        )}
      </main>
      <FooterLinks links={footerLinks} backgroundColour="#3e3e3e" />
      <Footer
        language={language}
        sponsorsText={sponsorsText}
        sponsorsLogos={getLogos(sponsorsLogos)}
        licensesLogos={getLogos(licensesLogos)}
        footerDisclaimer={footerDisclaimer}
        singleRgLogo={singleRgLogo}
        responsibleGamingLogos={getLogos(responsibleGamingFooterLogos)}
        background="#2b2d32"
      />
    </Layout>
  )
}

export default MultiOfferTemplate

MultiOfferTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired
}

export const pageQuery = graphql`
  query MultiOfferQuery($slug: String!, $language: String) {
    contentfulMultiOffer(slug: { eq: $slug }) {
      pageName
      language
      seoTitle
      slug
      defaultMediaId
      defaultMarketingSourceId
      offersListTitle
      casinoOffer {
        childContentfulLeoUniverseOfferMarkdownTextNode {
          childMarkdownRemark {
            rawMarkdownBody
            html
          }
        }
        childContentfulLeoUniverseLegalLineTextNode {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        childContentfulLeoUniverseTermsTextNode {
          childMarkdownRemark {
            rawMarkdownBody
            html
          }
        }
        bannerRibbonText
      }
      casinoDesktopImage {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      casinoMobileImage {
        fluid(maxWidth: 860) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      casinoCtaText
      livecasinoOffer {
        childContentfulLeoUniverseOfferMarkdownTextNode {
          childMarkdownRemark {
            rawMarkdownBody
            html
          }
        }
        childContentfulLeoUniverseLegalLineTextNode {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        childContentfulLeoUniverseTermsTextNode {
          childMarkdownRemark {
            rawMarkdownBody
            html
          }
        }
        bannerRibbonText
      }
      livecasinoDesktopImage {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      livecasinoMobileImage {
        fluid(maxWidth: 860) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      livecasinoCtaText
      sportsbookOffer {
        childContentfulLeoUniverseOfferMarkdownTextNode {
          childMarkdownRemark {
            rawMarkdownBody
            html
          }
        }
        childContentfulLeoUniverseTermsTextNode {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        childContentfulLeoUniverseLegalLineTextNode {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        bannerRibbonText
      }
      sportsbookDesktopImage {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      sportsbookMobileImage {
        fluid(maxWidth: 860) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      sportsbookCtaText
      termsOfferText
      loginText
      loginLinkText
      signUpText
      signUpLinkText
      bonusProgramId
      termsTitle {
        casino
        livecasino
        sportsbook
      }
    }
    contentfulGenericContent(language: { eq: $language }) {
      logo {
        file {
          url
        }
      }
      signupUrl
      loginUrl
      paymentsLogos {
        file {
          fileName
        }
        title
        description
        fixed(height: 32) {
          ...GatsbyContentfulFixed_withWebp_noBase64
          width
          height
        }
      }
      affiliatesLink
      aboutLeoVegasLink
      termsLink
      gameRulesLink
      responsibleGamingLink
      cookiePolicyLink
      privacyPolicyLink
      awardsSectionTitleLeoUniverse
      awardsImagesLeoUniverse {
        title
        description
        file {
          url
          fileName
          contentType
        }
        fixed(height: 140, quality: 100) {
          ...GatsbyContentfulFixed_withWebp_noBase64
          width
          height
        }
      }
      sponsorsText
      sponsorsLogos {
        file {
          url
        }
        title
        description
      }
      licensesLogos {
        file {
          url
        }
        title
        description
      }
      footerDisclaimer {
        childMarkdownRemark {
          html
        }
      }
      singleRgLogo {
        file {
          url
        }
        title
        description
      }
      responsibleGamingFooterLogos {
        file {
          url
        }
        title
        description
      }
      cookieConsentContent {
        buttons {
          accept
          manage
          confirm
          allowAll
        }
        necessary {
          title
          description
        }
        functional {
          title
          description
        }
        analytics {
          title
          description
        }
        marketing {
          title
          description
        }
        disclaimer
        disclaimerTitle
        cookiePolicyLink
      }
      cookiePolicy {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
    }
  }
`

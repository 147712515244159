import React from "react"
import PropTypes from "prop-types"
import * as S from "./style"
import MOBannerOffer from "./MOBannerOffer"
import MOTermsPopup from "../MOTermsPopup"
import { GATSBY_IMAGE_FLUID_PROPTYPE } from "../../helpers/constants"

const MOBanner = ({
  offersListTitle,
  casinoOffer,
  casinoImage,
  casinoCtaText,
  livecasinoOffer,
  livecasinoImage,
  livecasinoCtaText,
  sportsbookOffer,
  sportsbookImage,
  sportsbookCtaText,
  termsOfferText,
  loginUrl,
  loginText,
  loginLinkText,
  signUpUrlsByVertical
}) => {
  const { signupUrlCasino, signupUrlLivecasino, signupUrlSports } = signUpUrlsByVertical

  return (
    <S.Banner>
      <S.Inner>
        {offersListTitle && <S.Title>{offersListTitle}</S.Title>}
        <S.OfferList>
          {casinoOffer && casinoImage && (
            <MOBannerOffer
              offer={casinoOffer}
              image={casinoImage}
              ctaText={casinoCtaText}
              ctaUrl={signupUrlCasino}
              terms={termsOfferText}
              vertical="casino"
            />
          )}
          {livecasinoOffer && livecasinoImage && (
            <MOBannerOffer
              offer={livecasinoOffer}
              image={livecasinoImage}
              ctaText={livecasinoCtaText}
              ctaUrl={signupUrlLivecasino}
              terms={termsOfferText}
              vertical="live casino"
            />
          )}
          {sportsbookOffer && sportsbookImage && (
            <MOBannerOffer
              offer={sportsbookOffer}
              image={sportsbookImage}
              ctaText={sportsbookCtaText}
              ctaUrl={signupUrlSports}
              terms={termsOfferText}
              vertical="sports"
            />
          )}
        </S.OfferList>
        <S.SignUpLoginText>
          <span>
            {`${loginText} `}
            <a href={`${loginUrl}`} target="_blank" rel="noreferrer">
              {loginLinkText}
            </a>
          </span>
        </S.SignUpLoginText>
        <MOTermsPopup />
      </S.Inner>
    </S.Banner>
  )
}

export default MOBanner

MOBanner.propTypes = {
  offersListTitle: PropTypes.string,
  casinoOffer: PropTypes.objectOf(PropTypes.any).isRequired,
  casinoImage: GATSBY_IMAGE_FLUID_PROPTYPE.isRequired,
  casinoCtaText: PropTypes.string.isRequired,
  livecasinoOffer: PropTypes.objectOf(PropTypes.any).isRequired,
  livecasinoImage: GATSBY_IMAGE_FLUID_PROPTYPE.isRequired,
  livecasinoCtaText: PropTypes.string.isRequired,
  sportsbookOffer: PropTypes.objectOf(PropTypes.any).isRequired,
  sportsbookImage: GATSBY_IMAGE_FLUID_PROPTYPE.isRequired,
  sportsbookCtaText: PropTypes.string.isRequired,
  termsOfferText: PropTypes.string.isRequired,
  loginUrl: PropTypes.string.isRequired,
  loginText: PropTypes.string.isRequired,
  loginLinkText: PropTypes.string.isRequired,
  signUpUrlsByVertical: PropTypes.objectOf(PropTypes.any).isRequired
}

MOBanner.defaultProps = {
  offersListTitle: null
}

import React from "react"
import * as S from "./style"

const MOTermsPopup = () => {
  return (
    <S.PopupOverlay>
      <S.PopupContent>
        <S.PopupCloseButton onClick={() => window.close()}>X</S.PopupCloseButton>
      </S.PopupContent>
    </S.PopupOverlay>
  )
}

export default MOTermsPopup

import styled from "styled-components"
import ReactMarkdown from "react-markdown"

export const TermsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;

  width: 100%;

  background-color: #fff;

  font-family: ${props => props.theme.fonts.primary};
  padding 30px 0;
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1024px;
  padding: 0 16px;
`

export const CollapsibleTerms = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1024px;
`

export const IconWrapper = styled.div`
  display: flex;
  height: 3em;
  margin: 0 20px 0 60px;

  @media (max-width: 512px) {
    margin: 0 10px 0 20px;
  }
`

export const Icon = styled.img`
  right: 0;
  top: 42px;
  max-width: 20px;

  transform: rotate(${props => (props.isOpened ? "180" : "0")}deg);
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1024px;

  padding: 20px 0;
`

export const ItemHeader = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  text-decoration: none;
  color: #000;

  @media (max-width: 512px) {
    justify-content: space-between;
  }
`

export const ItemBody = styled.div`
  overflow: hidden;
  height: 0;

  ${props => props.isOpened && "height: auto;"}

  a.anchor {
    display: block;
    position: relative;
    top: 200px;
    visibility: hidden;
  }
`

export const HeaderTitle = styled.div`
  font-size: 1.5em;
  font-weight: 600;
  gap: 10px;
`

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 85%;
  span {
    font-size: 0.9em;
    line-height: 1.65em;
    text-decoration: underline;
  }
`

export const HeaderSignificantTerms = styled(ReactMarkdown)`
  display: flex;

  * {
    font-size: 0.9em;
    line-height: 1.65em;
    margin-bottom: 0;
  }

  p strong {
    font-weight: 700;
  }

  a {
    text-decoration: underline;
    color: #000;
  }
`

export const Markdown = styled(ReactMarkdown)`
  display: flex;
  flex-direction: column;

  * {
    font-size: 0.95em;
    line-height: 1.65em;
  }

  p strong {
    font-weight: 700;
  }

  ol,
  ul {
    padding: 10px 30px;
  }

  ul {
    li {
      list-style-type: disc;

      p {
        margin: 0;
      }
    }
  }

  ol {
    li {
      list-style-type: decimal;

      p {
        margin: 0;
      }
    }
  }

  ol[type="a"] {
    li {
      list-style-type: lower-alpha;
    }
  }

  a {
    text-decoration: underline;
    color: #000;
  }
`

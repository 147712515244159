import React from "react"
import PropTypes from "prop-types"

import rehypeRaw from "rehype-raw"
import Img from "gatsby-image"
import LUButton from "../../LUButton"
import * as S from "./style"
import { GATSBY_IMAGE_FLUID_PROPTYPE } from "../../../helpers/constants"
import { GtmEventType, GtmAction } from "../../../utils/handlers/gtmHandler"
import { getDangerousHtml } from "../../../helpers/index"

const getChildrenMarkdownHtml = value => value?.childContentfulLeoUniverseOfferMarkdownTextNode?.childMarkdownRemark?.html

const imageStyleProps = {
  style: {
    position: "relative",
    top: 0,
    width: "100%",
    height: "100%"
  },
  imgStyle: {
    objectFit: "cover"
  }
}
const MOBannerOffer = ({ offer, image, ctaText, ctaUrl, terms, vertical }) => {
  const ctaGtmData = {
    eventType: GtmEventType.BUTTON_CLICK,
    action: GtmAction.MULTIOFFER_CTA_CLICK,
    label: `${vertical} offer`
  }

  return (
    <S.BannerOffer href={ctaUrl}>
      <S.BannerMobile>{image && <Img fluid={image} {...imageStyleProps} />}</S.BannerMobile>
      {offer.bannerRibbonText && (
        <S.Ribbon $isSports={vertical === "sports"}>
          <S.RibbonText dangerouslySetInnerHTML={getDangerousHtml(offer.bannerRibbonText)} />
        </S.Ribbon>
      )}
      <S.Inner>
        <S.Markdown rehypePlugins={[rehypeRaw]}>{getChildrenMarkdownHtml(offer)}</S.Markdown>
        <S.Terms>{terms}</S.Terms>
        <S.CtaContainer>
          <LUButton bold url={ctaUrl} gtmData={ctaGtmData}>
            {ctaText}
          </LUButton>
        </S.CtaContainer>
      </S.Inner>
      {/* <MOTermsPopup /> */}
    </S.BannerOffer>
  )
}

export default MOBannerOffer

MOBannerOffer.propTypes = {
  offer: PropTypes.objectOf(PropTypes.any).isRequired,
  image: GATSBY_IMAGE_FLUID_PROPTYPE.isRequired,
  imageMobile: GATSBY_IMAGE_FLUID_PROPTYPE.isRequired,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  terms: PropTypes.string.isRequired,
  vertical: PropTypes.string.isRequired,
  ribbonText: PropTypes.string
}

MOBannerOffer.defaultProps = {
  ribbonText: null
}

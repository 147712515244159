import styled from "styled-components"

export const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100vh;
  z-index: 20;

  display: none;
  //   display: flex;
  justify-content: center;
  align-items: center;
`

export const PopupContent = styled.div`
  background-color: #f7f7f7;
  border-radius: 12px;
  width: 50%;
  min-height: 500px;
`

export const PopupCloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  cursor: pointer;
`

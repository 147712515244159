import React, { useState } from "react"
import PropTypes from "prop-types"

import rehypeRaw from "rehype-raw"
import * as S from "./style"

import accordionIcon from "../../assets/images/icons/icon-chevron-down.svg"

const getTermsOfferMarkdownHtml = offer => {
  return offer?.childContentfulLeoUniverseTermsTextNode?.childMarkdownRemark.html
}

const getLegalLineMarkdownHtml = offer => {
  return offer?.childContentfulLeoUniverseLegalLineTextNode?.childMarkdownRemark?.html
}

const MOTerms = ({ casinoOffer, livecasinoOffer, sportsbookOffer, termsTitle }) => {
  const [openedItem, setOpenedItem] = useState(false)

  const { casino: casinoTermsTitle, livecasino: livecasinoTermsTitle, sportsbook: sportsbookTermsTitle } = termsTitle

  const handleAccordionClick = index => {
    if (index !== openedItem) {
      setOpenedItem(index)
    } else {
      setOpenedItem(null)
    }
  }

  return (
    <S.TermsContainer>
      <S.Inner>
        <S.CollapsibleTerms>
          {casinoOffer && (
            <S.Item>
              <S.ItemHeader className="anchor" id="casino-terms" href="#casino-terms" onClick={() => handleAccordionClick(0)}>
                <S.HeaderContent>
                  <S.HeaderTitle>{casinoTermsTitle}</S.HeaderTitle>
                  <S.HeaderSignificantTerms rehypePlugins={[rehypeRaw]}>{getLegalLineMarkdownHtml(casinoOffer)}</S.HeaderSignificantTerms>
                  <span>Full T&C apply</span>
                </S.HeaderContent>

                <S.IconWrapper onClick={() => handleAccordionClick(casinoOffer)}>
                  <S.Icon src={accordionIcon} alt="" isOpened={openedItem === 0} />
                </S.IconWrapper>
              </S.ItemHeader>
              <S.ItemBody isOpened={openedItem === 0}>
                <S.Markdown rehypePlugins={[rehypeRaw]}>{getTermsOfferMarkdownHtml(casinoOffer)}</S.Markdown>
              </S.ItemBody>
            </S.Item>
          )}
          {livecasinoOffer && (
            <S.Item>
              <S.ItemHeader className="anchor" id="livecasino-terms" href="#livecasino-terms" onClick={() => handleAccordionClick(1)}>
                <S.HeaderContent>
                  <S.HeaderTitle>{livecasinoTermsTitle}</S.HeaderTitle>
                  <S.HeaderSignificantTerms rehypePlugins={[rehypeRaw]}>{getLegalLineMarkdownHtml(livecasinoOffer)}</S.HeaderSignificantTerms>
                  <span>Full T&C apply</span>
                </S.HeaderContent>
                <S.IconWrapper onClick={() => handleAccordionClick()}>
                  <S.Icon src={accordionIcon} alt="" isOpened={openedItem === 1} />
                </S.IconWrapper>
              </S.ItemHeader>
              <S.ItemBody isOpened={openedItem === 1}>
                <S.Markdown rehypePlugins={[rehypeRaw]}>{getTermsOfferMarkdownHtml(livecasinoOffer)}</S.Markdown>
              </S.ItemBody>
            </S.Item>
          )}
          {sportsbookOffer && (
            <S.Item>
              <S.ItemHeader className="anchor" id="sportsbook-terms" href="#sportsbook-terms" onClick={() => handleAccordionClick(2)}>
                <S.HeaderContent>
                  <S.HeaderTitle>{sportsbookTermsTitle}</S.HeaderTitle>
                  <S.HeaderSignificantTerms rehypePlugins={[rehypeRaw]}>{getLegalLineMarkdownHtml(sportsbookOffer)}</S.HeaderSignificantTerms>
                  <span>Full T&C apply</span>
                </S.HeaderContent>

                <S.IconWrapper onClick={() => handleAccordionClick()}>
                  <S.Icon src={accordionIcon} alt="" isOpened={openedItem === 2} />
                </S.IconWrapper>
              </S.ItemHeader>
              <S.ItemBody isOpened={openedItem === 2}>
                <S.Markdown rehypePlugins={[rehypeRaw]}>{getTermsOfferMarkdownHtml(sportsbookOffer)}</S.Markdown>
              </S.ItemBody>
            </S.Item>
          )}
        </S.CollapsibleTerms>
      </S.Inner>
    </S.TermsContainer>
  )
}

export default MOTerms

MOTerms.propTypes = {
  termsTitle: PropTypes.objectOf(PropTypes.any).isRequired,
  casinoOffer: PropTypes.objectOf(PropTypes.any).isRequired,
  livecasinoOffer: PropTypes.objectOf(PropTypes.any).isRequired,
  sportsbookOffer: PropTypes.objectOf(PropTypes.any).isRequired
}
